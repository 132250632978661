/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

.vs-match {
	margin-right: 10px; 
	font-weight: bold;
	font-size: 20px;
	background: linear-gradient(red, yellow, green);
	padding: 0 10px 0 10px;
	display: block;
	width: 50px;
	border-radius: 6px;
}

// Iframe video 
.video__content {
	// position: relative;
	// overflow: hidden;
	// height: 600px;

	.ske-sport-title-video {
		position: absolute;
		z-index: 0;
		bottom: 0px;
		left: 195px;
		// background-image: linear-gradient(red, orange);
		// background-image: linear-gradient(to left, rgba(255,0,0,0), rgba(255,0,0,1));
		background-image: linear-gradient(to right, black 0%, yellow 100%);
		
		display: flex;
		align-items: center;
		height: 38px;
		width: 420px;
		padding: 0 10px;

		p {
			font-size: 14px;
			span {
				font-size: 16px;
				font-weight: bold;
			}
		}
	}
}

.video iframe {
	display: block;       /* iframes are inline by default */
	border: none;         /* Reset default border */
	width: 100%;
}

// Scroll list chapters
.scroll-shadows {
  max-height: 200px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;

  background:
    /* Shadow Cover TOP */
    linear-gradient(
      white 30%,
      rgba(255, 255, 255, 0)
    ) center top,
    
    /* Shadow Cover BOTTOM */
    linear-gradient(
      rgba(255, 255, 255, 0), 
      white 70%
    ) center bottom,
    
    /* Shadow TOP */
    radial-gradient(
      farthest-side at 50% 0,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0)
    ) center top,
    
    /* Shadow BOTTOM */
    radial-gradient(
      farthest-side at 50% 100%,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0)
    ) center bottom;
  
  background-repeat: no-repeat;
  background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
  background-attachment: local, local, scroll, scroll;
}



/* Not important to the functionality, just for this particular demo */
.scroll-shadows {
  list-style: none;
  padding-right: 0.5rem;
}
.scroll-shadows > * {
  padding: 0.2rem;
}

.scroll-shadows li:hover {
  background: #cccc;
  border-radius: 3px;
  cursor: pointer;
}
// * {
//   margin: 0;
//   padding: 0;
//   box-sizing: border-box;
// }
// body {
//   height: 100vh;
//   display: grid;
//   place-items: center;
//   font: 500 100% system-ui, sans-serif;
// }
.scroll-shadows {
  --scrollbarBG: transparent;
  --thumbBG: #90a4ae;

  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}
.scroll-shadows::-webkit-scrollbar {
  width: 6px;
}
.scroll-shadows::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
.scroll-shadows::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}

// .loaderIcon {
//   margin-right: 7px;
//   top: 2px;
//   animation: spin 1s infinite linear;
// }
// Loading icon
.spinner-3 {
  position: absolute;
  left: 40%;
  top: 40%;
  width: 50px;
  padding: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #25b09b;
  --_m: 
    conic-gradient(#0000 10%,#000),
    linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
          mask: var(--_m);
  -webkit-mask-composite: source-out;
          mask-composite: subtract;
  animation: s3 1s infinite linear;
}
@keyframes s3 {to{transform: rotate(1turn)}}

.btn-delete {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  position: absolute;
  top: 25px;
  right: 10px;
  font-size: 16px;
  color: #EC5656;
  width: 35px;
  height: 35px;
  border-radius: 25px;
  border: 1px solid rgb(231, 50, 50);
  background-color: #fff;
  cursor: pointer;
  box-shadow: 0 0 5px #333;
  overflow: hidden;
  transition: 0.3s;

  &:hover {
    background-color: rgb(245, 207, 207);
    transform: scale(1.2);
    box-shadow: 0 0 4px #111;
    transition: 0.3s;
  }
}